const config = () =>{
    return {
    REGION: process.env.REACT_APP_REGION,
    USER_POOL_ID: process.env.REACT_APP_USER_POOL_ID,
    APP_CLIENT_ID: process.env.REACT_APP_APP_CLIENT_ID,
    IDENTITY_POOL_ID:process.env.REACT_APP_IDENTITY_POOL_ID,
    S3_BUCKET:process.env.REACT_APP_S3_BUCKET,
    S3_CLIENT_PORTAL_BUCKET:process.env.REACT_APP_S3_CLIENT_PORTAL_BUCKET,
    ACL:process.env.REACT_APP_ACL
    }
}

const serviceParam = () => {
    return {
        region: process.env.REACT_APP_REGION
      };
}
export { config,serviceParam } ;