import React,{ useState} from "react"
import ScrollToTop from "react-scroll-up"
import { Button, Input } from "reactstrap"
import { ArrowUp } from "react-feather"
import classnames from "classnames"
import LocalStore from "../../../authServices/Auth";
import * as Icon from "react-feather";
import Text from "../../../userGuid/Text"

const Footer = props => {
  let footerTypeArr = ["sticky", "static", "hidden"];
  const [isEditFooter, setEditFooter] = useState(false);
  const [footer, setChangeFooter] = useState("");

  let isAdmin = false;
  const whiteLabelInfoType = {
    LOGO:"LOGO",
    HEADER:"HEADER",
    FOOTER:"FOOTER"
  }
  const key = LocalStore.getStorageByKey("user");


  if (
    JSON.parse(key)["cognito:groups"] &&
    (JSON.parse(key)["cognito:groups"].includes("Admin")||JSON.parse(key)["cognito:groups"].includes("Super"))
  ) {
    isAdmin = true;
  }

  return (
    <footer
      className={classnames("footer footer-light", {
        "footer-static": props.footerType === "static" || !footerTypeArr.includes(props.footerType),
        "d-none": props.footerType === "hidden"
      })}
    >
      <Text data-tut="reactour__footer" style={{width:"50%"}}>
      <div className="mb-0 clearfix ">
       
        {!isEditFooter && (
                    <span className="float-md-left d-block d-md-inline-block mt-25">
                    {props.whiteLabelInfo && props.whiteLabelInfo.footerText}
                   </span>
                  )}

                  <div style={{display:"flex"}}>
                  {isEditFooter && (
                    <Input value={footer} onChange={(event)=>setChangeFooter(event.target.value)} style={{width:"50%"}}/>
                  )}
                  {isAdmin && !isEditFooter && (
                    <Icon.Edit
                      size={32}
                      className="ml-2 fonticon-wrap"
                      onClick={() => {setEditFooter(true); setChangeFooter(props.whiteLabelInfo && props.whiteLabelInfo.footerText);}}
                    />
                  )}
                  {isAdmin && isEditFooter && (
                    <Icon.CheckCircle
                      size={32}
                      className="ml-2 fonticon-wrap"
                      onClick={() => {props.updateWhiteLabelInfo(whiteLabelInfoType.FOOTER,footer);setEditFooter(false)}}
                    />
                  )}
                  </div>

        {/* <span className="float-md-right d-none d-md-block">
          <span className="align-middle">Hand-crafted & Made with</span>{" "}
          <Heart className="text-danger" size={15} />
        </span> */}
      </div>
      </Text>

      {props.hideScrollToTop === false ? (
        <ScrollToTop showUnder={160}>
          <Button color="primary" className="btn-icon scroll-top">
            <ArrowUp size={15} />
          </Button>
        </ScrollToTop>
      ) : null}
    </footer>
   
  )
}

export default Footer
