export default class AuthToken {
    static STORAGE_KEY = "token";

    static getToken() {
        if (typeof window !== 'undefined')
            return window.localStorage.getItem(AuthToken.STORAGE_KEY);
        else
            return true;
    }
   
    static setToken(token) {
        window.localStorage.setItem(AuthToken.STORAGE_KEY, token);
    }

    static removeToken() {
        window.localStorage.removeItem(AuthToken.STORAGE_KEY);
    }
}


