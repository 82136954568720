import React, { useState, useEffect, Fragment, useContext } from "react";
import { Navbar, Input, Button } from "reactstrap";
import { connect } from "react-redux";
import classnames from "classnames";
import { useAuth0 } from "../../../authServices/auth0/auth0Service";
import {
  logoutWithJWT,
  logoutWithFirebase,
} from "../../../redux/actions/auth/loginActions";
import NavbarBookmarks from "./NavbarBookmarks";
import NavbarUser from "./NavbarUser";
import userImg from "../../../assets/img/portrait/small/avatar-s-11.jpg";
// import logo from "../../../assets/img/logo/logo-myoderm-header.svg";
import { history } from "../../../history";
import s3 from "aws-sdk/clients/s3";
import { serviceParam, config } from "../../../configs/configSetting";
import { Modal } from "antd";
import LocalStore from "../../../authServices/Auth";
import axios from "axios";
import Amplify, { Auth } from 'aws-amplify';
import AWS from "aws-sdk";
import * as Icon from "react-feather";
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";
import Box from "../../../userGuid/Box";
import Row from "../../../userGuid/Row";
import Context from '../../../GlobalStateStore/Context';
import { data } from "jquery";

const params = serviceParam();
const configuration = config();

const UserName = (props) => {
  let username = "";
  if (props.userdata !== undefined) {
    username = props.userdata.name;
  } else if (props.user.login.values !== undefined) {
    username = props.user.login.values.name;
    if (
      props.user.login.values.loggedInWith !== undefined &&
      props.user.login.values.loggedInWith === "jwt"
    ) {
      username = props.user.login.values.loggedInUser.name;
    }
  } else {
    username = "John Doe";
  }

  return username;
};
const ThemeNavbar = (props) => {
  // const [isEditHeader, setEditHeader] = useState(false);
  // const [header, setChangeHeader] = useState("");
  const [isUploadVisible, setUploadModalVisibilty] = useState(false);
  const [isPortalName, setIsPortalName] = useState(false);
  const [portalName, setPortalName] = useState("")
  const { user } = useAuth0();
  const colorsArr = ["primary", "danger", "success", "info", "warning", "dark"];
  const navbarTypes = ["floating", "static", "sticky", "hidden"];
  const key = LocalStore.getStorageByKey("user");
  let isAdmin = false, isSystem = false, isSuper = false;

  const whiteLabelInfoType = {
    LOGO: "LOGO",
    HEADER: "HEADER",
    FOOTER: "FOOTER",
  };

  const uploadFileToS3 = (e) => {
    let file = e.target.files[0];

    let reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = async function () {
      const base64String = reader.result;
      var BASE64_MARKER = ";base64,";
      var base64Index =
        base64String.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
      var base64 = base64String.substring(base64Index);
      var raw = window.atob(base64);
      var rawLength = raw.length;
      var array = new Uint8Array(new ArrayBuffer(rawLength));

      for (let i = 0; i < rawLength; i++) {
        array[i] = raw.charCodeAt(i);
      }
      var blob = new Blob([array], { type: file.type });
      console.log()
      try {
        let signedUrl = await axios.get(`${process.env.REACT_APP_API_END_POINT}getSignedUrl?key=78/${file.name}&type=${file.type}`,
          {
            headers: {
              'Authorization': await LocalStore.getLambdaAPIToken()
            }
          }
        );
        await axios.put(signedUrl.data,
          blob
          , {
            headers: {
              'Content-Type': file.type
            }
          });
        let fileLocation = `https://${process.env.REACT_APP_S3_CLIENT_PORTAL_BUCKET}.s3.amazonaws.com/78/${file.name}`
        props.updateWhiteLabelInfo(whiteLabelInfoType.LOGO, fileLocation);
        setUploadModalVisibilty(false);
      } catch (e) {
        console.log("error", e)
      }
    };

    reader.onerror = function () {

    };
  };

  useEffect(() => {
    getWhiteLabelInfo()
  }, [])

  if (
    JSON.parse(key)["cognito:groups"] &&
    (JSON.parse(key)["cognito:groups"].includes("Admin") || JSON.parse(key)["cognito:groups"].includes("Super"))
  ) {
    isAdmin = true;
  }
  if (JSON.parse(key)["cognito:groups"].includes("Admin") && JSON.parse(key)["cognito:groups"].includes("System")) {
    isSystem = true;
  }
  if (JSON.parse(key)["cognito:groups"].includes("Super")) {
    isSuper = true;
  }
  const updatePortalName = async () => {
    console.log("update", portalName)
    axios.get(`${process.env.REACT_APP_API_END_POINT}whitelabelPut?orgID=${JSON.parse(key)["given_name"]}&type=PORTAL&value=${portalName}`, {
      headers: {
        'Authorization': await LocalStore.getLambdaAPIToken()
      }
    }
    ).then((data) => {
      setIsPortalName(false)
      getWhiteLabelInfo();
    }).catch(error => console.log(error))
  }
  const getWhiteLabelInfo = async () => {
    const key = LocalStore.getStorageByKey("user");
    // console.log("key",JSON.parse(key)["given_name"]);
    axios.get(`${process.env.REACT_APP_API_END_POINT}whitelabelGet?orgID=${JSON.parse(key)["given_name"]}`,
      {
        headers: {
          'Authorization': await LocalStore.getLambdaAPIToken()
        }
      }).then((response) => {
        // handle success
        // console.log("whitelabelGet",response)
        if (response.data[0])
          setPortalName(response.data[0].portalName);
      })

  };
  const { state, actions } = useContext(Context);

  const closeUserGuide = async () => {
    const baseUrl = `${process.env.REACT_APP_API_END_POINT}userGuide`;
    let user = JSON.parse(localStorage.getItem('user'));
    document.body.style.overflowY = 'auto'
    actions({ type: 'setState', payload: { ...state, isTourOpen: false } });
    axios.get(`${baseUrl}?type=updateUserGuide&uid=${user["cognito:username"]}&userGuide=false`, {
      headers: {
        'Authorization': await LocalStore.getLambdaAPIToken()
      }
    }).then((data) => {
      let updateUser = user;
      updateUser["custom:userGuide"] = "false";
      localStorage.setItem('user', JSON.stringify(updateUser));
    }).catch(error => console.log(error))
  }
  return (
    <React.Fragment>
      <div className="content-overlay" />
      <div className="header-navbar-shadow" />
      <Navbar
        className={classnames(
          "header-navbar navbar-expand-lg navbar navbar-with-menu navbar-shadow",
          {
            "navbar-light":
              props.navbarColor === "default" ||
              !colorsArr.includes(props.navbarColor),
            "navbar-dark": colorsArr.includes(props.navbarColor),
            "bg-primary":
              props.navbarColor === "primary" && props.navbarType !== "static",
            "bg-danger":
              props.navbarColor === "danger" && props.navbarType !== "static",
            "bg-success":
              props.navbarColor === "success" && props.navbarType !== "static",
            "bg-info":
              props.navbarColor === "info" && props.navbarType !== "static",
            "bg-warning":
              props.navbarColor === "warning" && props.navbarType !== "static",
            "bg-dark":
              props.navbarColor === "dark" && props.navbarType !== "static",
            "d-none": props.navbarType === "hidden" && !props.horizontal,
            "floating-nav":
              (props.navbarType === "floating" && !props.horizontal) ||
              (!navbarTypes.includes(props.navbarType) && !props.horizontal),
            "navbar-static-top":
              props.navbarType === "static" && !props.horizontal,
            "fixed-top": props.navbarType === "sticky" || props.horizontal,
            scrolling: props.horizontal && props.scrolling,
          }
        )}
      >
        <div className="navbar-wrapper">
          <div className="navbar-container content">
            <div
              className="navbar-collapse d-flex justify-content-between align-items-center"
              id="navbar-mobile"
            >
              <Row data-tut="reactour__Logo">
                <div className="bookmark-wrapper header-logo-container">
                  <NavbarBookmarks
                    sidebarVisibility={props.sidebarVisibility}
                    handleAppOverlay={props.handleAppOverlay}
                  />
                  <img
                    src={
                      (props.whiteLabelInfo && props.whiteLabelInfo.logoUrl) || ""
                    }
                    alt="Logo"
                    width={"auto"}
                    height={35}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      history.push("/");
                    }}
                  />
                  {isAdmin && (
                    <Fragment>
                      <Modal
                        visible={isUploadVisible}
                        footer={null}
                        title="Edit Logo"
                        onCancel={() => {
                          setUploadModalVisibilty(false);
                        }}
                      >
                        <div>
                          <input
                            multiple={false}
                            accept="image/*"
                            type="file"
                            onChange={uploadFileToS3}
                          />
                        </div>
                      </Modal>
                      <UncontrolledDropdown
                        tag="span"
                        className="dropdown-user nav-item"
                      >
                        <DropdownToggle
                          tag="span"
                          className="nav-link dropdown-user-link"
                        >
                          <Icon.Edit size={32} className="mr-4 fonticon-wrap" />
                        </DropdownToggle>
                        <DropdownMenu right>
                          <DropdownItem tag="span">
                            <span
                              className="align-middle"
                              onClick={() => {
                                setUploadModalVisibilty(true);

                              }}
                            >
                              Edit Logo
                            </span>
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </Fragment>
                  )}
                  {

                    <Fragment>
                      <Box data-tut="reactour__editPortal">
                        <Modal
                          visible={isPortalName}
                          footer={
                            <Button className="primary" onClick={updatePortalName}>
                              Submit
                            </Button>
                          }
                          title="Edit Portal Name"
                          onCancel={() => {
                            setIsPortalName(false);
                          }}
                          okText="Update"
                        >
                          <div>
                            <Input
                              placeholder="Enter Portal Name"
                              value={portalName}
                              onChange={e => setPortalName(e.target.value)}
                            />
                          </div>
                        </Modal>
                      </Box>
                    </Fragment>
                  }
                </div>
              </Row>
              {props.horizontal ? (
                <div className="logo d-flex align-items-center">
                  <Row data-tut="reactour__portal">

                    {/* <img src={logo} width={50} height={50}></img> */}
                    <h2 className="text-primary brand-text mb-0">
                      {portalName ? portalName : "Client Portal"}
                    </h2>
                    {(isSystem || isSuper) && <Icon.Edit size={32} className="mr-4 fonticon-wrap ml-1 mt-1"
                      onClick={() => {
                        setIsPortalName(true);
                        closeUserGuide();
                      }} />}
                  </Row>

                </div>
              ) : null}
              <Row data-tut="reactour__logout">
                <NavbarUser
                  handleAppOverlay={props.handleAppOverlay}
                  changeCurrentLang={props.changeCurrentLang}
                  userName={<UserName userdata={user} {...props} />}
                  userImg={
                    props.user.login.values !== undefined &&
                      props.user.login.values.loggedInWith !== "jwt" &&
                      props.user.login.values.photoUrl
                      ? props.user.login.values.photoUrl
                      : user !== undefined && user.picture
                        ? user.picture
                        : userImg
                  }
                  loggedInWith={
                    props.user !== undefined &&
                      props.user.login.values !== undefined
                      ? props.user.login.values.loggedInWith
                      : null
                  }
                  logoutWithJWT={props.logoutWithJWT}
                  logoutWithFirebase={props.logoutWithFirebase}
                />
              </Row>
            </div>
          </div>
        </div>
      </Navbar>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth,
  };
};

export default connect(mapStateToProps, {
  logoutWithJWT,
  logoutWithFirebase,
  useAuth0,
})(ThemeNavbar);
