import AuthStore from './AuthToken.js';
import {Auth} from 'aws-amplify';
export default class LocalStore {
	static USER_KEY = 'user';
	static AUTHENTICATE_KEY = 'isAuthenticated';
	static isSignedInIn() {
		var result = !!AuthStore.getToken();
		return result;
	}

	static getUser() {
		if (typeof window !== 'undefined') return window.localStorage.getItem(LocalStore.USER_KEY);
		else return true;
	}

	static isAuthenticated() {
		if (typeof window !== 'undefined') return JSON.parse(window.localStorage.getItem(LocalStore.AUTHENTICATE_KEY));
		else return false;
	}

	static setAuthenticated(auth) {
		window.localStorage.setItem(LocalStore.AUTHENTICATE_KEY, auth);
	}

	static setUser(user) {
		window.localStorage.setItem(LocalStore.USER_KEY, JSON.stringify(user));
	}
	static getUserObj() {
		if (typeof window !== 'undefined') {
			return JSON.parse(window.localStorage.getItem(LocalStore.USER_KEY) || '');
		} else return true;
	}
	static getStorageByKey(key) {
		if (typeof window !== 'undefined') return window.localStorage.getItem(key);
		else return true;
	}
	static setStorageByKey(key, value) {
		window.localStorage.setItem(key, value);
	}
	static capitalizedInstance() {
		return this.isAuthenticated ? (JSON.parse(this.getUser()).name ? JSON.parse(this.getUser()).name :  JSON.parse(this.getUser()).email.substr(0, JSON.parse(this.getUser()).email.indexOf('@'))) : null
	}
	static async getLambdaAPIToken() {
		// if (typeof window !== 'undefined') {
		// 	const userObj= this.getUserObj();
		// 	return window.localStorage.getItem(`CognitoIdentityServiceProvider.${userObj.aud}.${userObj.sub}.idToken`);
		// }
		// else return true;
		let token = (await Auth.currentSession()).getIdToken().getJwtToken();
			return token;
	}
	// static getLambdaAPIToken() {
	// 	if (typeof window !== 'undefined') {
	// 		const userObj= this.getUserObj();
	// 		return window.localStorage.getItem(`CognitoIdentityServiceProvider.${userObj.aud}.${userObj.sub}.idToken`);
	// 	}
	// 	else return true;
	// }
	static setCategory(cat) {
		window.localStorage.setItem("CATEGORY", cat);
	}
	static getCategory() {
		if (typeof window !== 'undefined') return window.localStorage.getItem("CATEGORY");
		else return true;
	}
}
